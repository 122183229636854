/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { CSSTransition } from 'react-transition-group'
import Img from 'gatsby-image'

// Libraries
import _ from 'lodash'

// Components
import CustomLink from 'components/CustomLink'

// CSS
import './Header.scss'

// Images
import imgLogo from 'img/KMS_LOGO.svg'

// Icons
import chevronDown from 'img/icon/down-arrow.svg'

const Header = () => {
  const {
    pages,
    wordpressMenusMenusItems: { items: menuItems },
    allWordpressWpSocials: { edges: socials },
    hqLocation
  } = useStaticQuery(graphql`
    {
      pages: allWordpressPage {
        edges {
          node {
            wpId: wordpress_id
            title
            path
            parent: parent_element {
              wpId: wordpress_id
            }
          }
        }
      }
      wordpressMenusMenusItems(wordpress_id: {eq: 2}) {
        items {
          title
          slug
          wpId: object_id
        }
      }
      allWordpressWpSocials {
        edges {
          node {
            acf {
              location
              icon {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 30) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
      hqLocation: wordpressWpLocaties(acf: {hq: {eq: true}}) {
        acf {
          phonenumber
        }
      }
    }
  `)

  const [isCollapsed, setCollapsed] = useState(true)

  return (
    <nav className="header">
      <div className="navbar-container container py-5 position-relative">
        <div className="header-top">
          {_.map(socials, ({ node: { title, acf } }, index) => (
            <a
              key={index}
              className="header-top-social d-inline-block"
              href={acf.location}
              target="_blank"
              rel="noopener noreferrer"
              alt={title}
            >
              <Img className="header-top-social-image" fluid={acf.icon.localFile.childImageSharp.fluid} />
              Ga naar onze
              {' '}
              {title}
            </a>
          ))}
          <a className="header-top-phone d-inline-block" href={`tel:${hqLocation.acf.phonenumber}`}>
            {hqLocation.acf.phonenumber}
          </a>
        </div>
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <img src={imgLogo} alt="" />
            Home
          </Link>
        </div>

        <button type="button" onClick={() => setCollapsed(!isCollapsed)} className={`navbar-collapse-button${isCollapsed ? ' navbar-collapse-button-collapsed' : ''} text-center d-block d-lg-none`}>
          {isCollapsed ? (
            <>
              <span className="navbar-collapse-button-stripe d-block" />
              <span className="navbar-collapse-button-stripe d-block" />
              <span className="navbar-collapse-button-stripe d-block" />
            </>
          ) : (
            <div className="navbar-collapse-button-close" />
          )}
          Menu
        </button>

        <HeaderMenuMobile pages={pages} menuItems={menuItems} isCollapsed={isCollapsed} setCollapse={setCollapsed} />
        <HeaderMenuDesktop pages={pages} menuItems={menuItems} />
      </div>
    </nav>
  )
}

const HeaderMenuMobile = ({ pages, isCollapsed, menuItems }) => (
  <CSSTransition in={!isCollapsed} timeout={300} unmountOnExit classNames="header-menu">
    <div className="navbar-menu">
      <ul className="navbar-items">
        <HeaderMenuItems menuItems={menuItems} pages={pages} />
      </ul>
    </div>
  </CSSTransition>
)

const HeaderMenuDesktop = ({ pages, menuItems }) => (
  <ul className='navbar-items d-none py-2 d-lg-flex'>
    <HeaderMenuItems menuItems={menuItems} pages={pages} />
  </ul>
)

const HeaderMenuItems = ({ menuItems, pages }) => {
  return (
    <>
      <li>
        <Link to="/">
          Home
        </Link>
      </li>
      {_.map(menuItems, ({ title, slug, wpId }) => {
        let subPages = []

        if (pages) {
          subPages = pages.edges.filter(({ node }) => node.parent && node.parent.wpId !== 2 && parseFloat(node.parent.wpId) === parseFloat(wpId))
        }

        const hasSubPages = subPages && subPages.length > 0

        return (
          <li key={slug} className="position-relative">
            <CustomLink to={`/${slug}`}>
              {title}
              {hasSubPages && (
                <img src={chevronDown} alt="" />
              )}
            </CustomLink>
            {hasSubPages && (
              <div className="navbar-items-submenu py-lg-3 px-lg-3">
                {subPages.map(({ node }) => (
                  <CustomLink className="navbar-items-submenu-item" key={node.wpId} to={node.path}>
                    <div dangerouslySetInnerHTML={{ __html: node.title }} />
                  </CustomLink>
                ))}
              </div>
            )}
          </li>
        )
      })}
    </>
  )
}

export default Header
