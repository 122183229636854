import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Libraries
import { find as _find, map as _map, size as _size } from 'lodash'

// CSS
import './Footer.scss'

// Images
import imgLogo from 'img/KMS_LOGO.svg'

const Footer = () => {
  const {
    wordpressMenusMenusItems: { items: menuItems },
    allWordpressWpLocaties: { edges: locations },
    allWordpressWpSocials: { edges: socials },
  } = useStaticQuery(graphql`
    {
      wordpressMenusMenusItems(wordpress_id: { eq: 2 }) {
        items {
          title
          slug
        }
      }
      allWordpressWpLocaties {
        edges {
          node {
            title
            acf {
              streetname
              housenumber
              zipcode
              city
              phonenumber
              emailAddress: email_address
              hq
            }
          }
        }
      }
      allWordpressWpSocials {
        edges {
          node {
            acf {
              location
              icon {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 30) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { node: hqLocation } = _find(
    locations,
    ({
      node: {
        acf: { hq },
      },
    }) => hq
  )

  return (
    <div className="footer py-5">
      <div className="container">
        <div className="row text-center text-lg-left">
          <div className="col-lg-3">
            <img src={imgLogo} alt="" />
          </div>
          <div className="col-lg-3 mt-5 mt-lg-0">
            <h4>Menu</h4>
            <div className="mt-4">
              <Link className="footer-link mt-1" to="/">
                Home
              </Link>
              {_map(menuItems, ({ title, slug }) => (
                <Link className="footer-link mt-1" key={slug} to={`/${slug}`}>
                  {title}
                </Link>
              ))}
            </div>
          </div>
          <div className="col-lg-3 mt-5 mt-lg-0">
            <h4>
              Locatie
              {_size(locations) !== 1 && (<span>s</span>)}
            </h4>
            <div className="mt-4">
              {_map(locations, ({ node: { title, acf } }, index) => (
                <div className="footer-location mb-3" key={index}>
                  <div className="font-italic font-weight-l">
                    Locatie
                    {' '}
                    {title}
                  </div>
                  {acf.streetname}
                  {' '}
                  {acf.housenumber}
                  <br />
                  {acf.zipcode}
                  {' '}
                  {acf.city}
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-3 mt-5 mt-lg-0">
            <h4>Contact</h4>
            <div className="mt-4">
              <div>
                <a
                  className="d-inline-block color-background-main py-2 px-3 color-text-light font-size-s"
                  href={`tel:${hqLocation.acf.phonenumber}`}
                >
                  {hqLocation.acf.phonenumber}
                </a>
              </div>
              <div>
                <a
                  className="d-inline-block color-background-light mt-3 py-2 px-3 color-text-main font-size-s"
                  href={`mailto:${hqLocation.acf.emailAddress}`}
                >
                  {hqLocation.acf.emailAddress}
                </a>
              </div>
              <div className="mt-3">
                {_map(socials, ({ node: { title, acf } }, index) => (
                  <a
                    key={index}
                    className="footer-social mr-3"
                    href={acf.location}
                    title={title}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Img className="footer-social-image" fluid={acf.icon.localFile.childImageSharp.fluid} />
                    Ga naar onze
                    {' '}
                    {title}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
