/* eslint-disable import/no-unresolved */
import React from 'react'
import CookieConsent from 'react-cookie-consent'

// Components
import Header from './Header'
import Footer from './Footer'

// CSS
import 'styles/all.scss'

const Layout = ({ children }) => (
  <>
    <Header />
    <>{children}</>
    <Footer />
    <CookieConsent
      acceptOnScroll
      acceptOnScrollPercentage={20}
      location="bottom"
      style={{ backgroundColor: '#D70A0A', color: '#FFFFFF' }}
      buttonClasses="d-none"
    >
      <div className="container px-lg-5">
        <div className="px-lg-5">
          <div>We gebruiken cookies om ervoor te zorgen dat onze website zo soepel mogelijk draait. Als u doorgaat met het gebruiken van de website, gaan we er vanuit dat u ermee instemt.</div>
        </div>
      </div>
    </CookieConsent>

  </>
)

export default Layout
